* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica', sans-serif;
}

html {
  min-height: 100%;
  scroll-behavior: smooth;
  cursor: crosshair;
  // overflow: none;
  // cursor: url("/imgs/c1.svg"), pointer;
        // cursor: crosshair;
  // cursor:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAaCAMAAABigZc2AAAACVBMVEX///8AAAD///9+749PAAAAAXRSTlMAQObYZgAAAFpJREFUeNp9jUEOwEAIAhn+/+imjbs1bC0XzQioSUAAYZs0mcVY051RbGXfFsyxibTVHXhuhXYlbuRGPbe7kz3g0wf679OuizDBdCaberLE4AsjGliD02eNugA+MQFAPqynHQAAAABJRU5ErkJggg==),auto; 
  // background: #393935;
  // border-left: 0.5rem solid red;
}

body {
  // overflow: none;
  background: #FFFDFA;
  min-height: 100vh;
  // border-left: 0.5rem solid red;
  // overflow: hidden;
  // background: black;
}

// // @import '~bootstrap/scss/bootstrap';

// body {
//   // overflow: hidden;
//   position: relative;
//   margin: 0;
//   height: 100%;
// }

// // #project-img {
// //   margin-top: 2%;
// //   margin-left: 2%;
// // }

// #nektine-img {
//   max-width: 96%;
//   margin-left: 2%;
// }

// img[src*="_W"] {
//   margin-top: 2%;
//   margin-right: 2%;
//   margin-left: 2%;
//   // border: red solid;
//   max-width: 90%;
// }

// img[src*="_T"] {
//   // max-width: 85%;
//   max-height: 89vh;
//   margin-top: 2%;
//   margin-left: 2%;
//   margin-right: 2%;
//   // border: plum solid;
// }

// .container {
//   overflow: auto;
//   position: absolute;
//   // background-color: #eee6ff;
//   height: 100vh;
//   width: 100vw;
//   display: flex;
//   flex-direction: column;
//   flex-shrink: 0;
// }

// .frame {
//   position: relative;
//   overflow: hidden;
//   align-self: center;
//   width: 97vw;
//   height: 97vh;
//   margin: 1.5vh;
//   margin-bottom: 1.5vh;
//   z-index: 4;
//   border: 1px solid #2F6D61;
// }

// .nav-bar {
//   position: relative;
//   width: 97vw;
//   height: 4vh;
//   z-index: 2;
//   border: 1px solid #2F6D61;
// }

// // .dot {
// //   width: 14px;
// //   height: 14px;
// //   border: 1.5px solid #000;
// //   border-radius: 31.5px;
// //   position: absolute;
// //   top: 2.83%;
// //   left: -1.5em;
// //   content: "";
// //   margin-top: -11.5px;
// // }

// .wrapper {
//   position: relative;
// }

// // // WORK ----------------------------------------

// // body, .pushy {
// // 	padding: 2em;
// // }

// .work {
//   position: absolute;
//   overflow: hidden;
//   height: 100%;
//   width: 100%;
//   display: grid;
//   grid-template-columns: 23vw 25vw 52vw;
//   z-index: 3;
// }

// .projects {
//   overflow-y: auto;
//   border: 1px solid #2F6D61;
//   background-color: aliceblue;
//   z-index: 1;
// }

// .a-project {
//   overflow-y: auto;
//   // border: 1px solid #2F6D61;
//   // background-color: salmon;
//   overflow-y: scroll;
//   z-index: 5;
//   width: 100%;
//   height: 100%;
//   // margin-bottom: 50vh;
// }

// .description {
//   // border: 1px solid #2F6D61;
//   margin-left: 3%;
//   // background-color:lawngreen;
// }

// thead {
//   display: block;
// }

// tbody {
//   display: block;
//   max-height: 88vh;
//   overflow: auto;
//   z-index: 5;
// }

// .directory {
//   padding: 2%;
//   grid-column: 1/3;
//   grid-row: 2 / 20;
//   // border: 1px solid #2F6D61;
//   // overflow-y: auto;
// }

// .display {
//   // display: block;
//   width: 100%;
//   height: 100%;
//   border: 1px solid #2F6D61;
//   background-color: salmon;
//   overflow-y: auto;
//   // overflow-x: auto;
//   // position: fixed;
// }

// // .work-desc {
// //   padding: 1em;
// //   grid-column: 6/8;
// //   grid-row: 2 / 20;
// //   // border: 1px solid #2F6D61;
// // }

// // NEKTINE ----------------------------------------

// .nektine {
//   position: relative;
//     bottom: 0;
//     width: 100%;
//     max-height: 96vh;
//     display: flex;
//     flex-direction: column;
//     flex-shrink: 0;
//     z-index: 3;
// }

// .nektine-projects{
//   overflow-y: auto;
//   background-color: rgba(242, 242,242, 0.9);
//   padding: 0.25em;
// }

// // LANDING ----------------------------------------

// .landing-page {
//   background-color: greenyellow;
//   position: relative;
//   overflow-x: scroll;
// }

// .name {
//   font-size: 12em;
//   letter-spacing: -10px;
//   line-height: 83%;
// }

// // .desc1 {
// //   font-size: 9em;
// //   letter-spacing: -5px;
// //   line-height: 85%;
// //   width: 80%;
// // }

// // .down-arrow {
// //   margin-top: -15.5%;
// //   margin-left: 42%;
// //   font-size: 7em;
// // }

// // ABOUT ----------------------------------------

// // .about-left {
// //   position: fixed;
// //   width: 100%;
// //   height: 100%;
// //   top: 3em;
// //   border: 1px solid #2F6D61;
// // }

// // SCROLLBAR ----------------------------------------

// #style-8::-webkit-scrollbar-track
// {
//   border: 1px solid black;
//   background-color: #F5F5F5;
// }

// #style-8::-webkit-scrollbar
// {
//   width: 10px;
//   background-color: #F5F5F5;
// }

// #style-8::-webkit-scrollbar-thumb
// {
//   background-color: #000000;  
// }
